// Home.js
import React from 'react';
import { Carousel } from 'react-bootstrap';

const Carrusel = () => {
  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://via.placeholder.com/800x400"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Primer Slide</h3>
            <p>Descripción del primer slide.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://via.placeholder.com/800x400"
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>Segundo Slide</h3>
            <p>Descripción del segundo slide.</p>
          </Carousel.Caption>
        </Carousel.Item>
        {/* Agrega más items según sea necesario */}
      </Carousel>
    </div>
  );
};

export default Carrusel;
