import React from 'react';
import Videos from './components/Videos.js';
import Footer from './components/Footer.js';
import Nosotros from './components/Nosotros.js';
import 'bootstrap/dist/css/bootstrap.min.css';



const Home = () => {
  return (
    <div>
        <Nosotros />
      <Videos />
      <Footer />
    </div>
  );
};

export default Home;