// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Barra from './components/barra.js';
import Home from './Home';
import Nosotros from './nosotros.js';
import Preguntas from './preguntas.js';
import Carrito from './Carrito.js';

const App = () => {
  return (
    <Router>
      <Barra />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/preguntas" element={<Preguntas />} />
        <Route path="/carrito" element={<Carrito />} />
      </Routes>
    </Router>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
