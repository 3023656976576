import React from 'react';

import Carrusel from './components/Carrusel.js';
import Comentarios from './components/Comentarios.js';
import Videos from './components/Videos.js';
import Footer from './components/Footer.js';
import Tienda from './components/Tienda.js';
import 'bootstrap/dist/css/bootstrap.min.css';



const Home = () => {
  return (
    <div>
      <Carrusel />
      <Tienda />
      <Comentarios />
      <Videos />
      <Footer />
    </div>
  );
};

export default Home;