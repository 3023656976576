// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBoCJwPy2yVKF9DQWAqRS8fwCuyIh0MvJQ",
  authDomain: "nino-92a6e.firebaseapp.com",
  projectId: "nino-92a6e",
  storageBucket: "nino-92a6e.appspot.com",
  messagingSenderId: "245425217579",
  appId: "1:245425217579:web:644de8c07ea421c49d4a6e"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
