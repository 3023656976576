import React from 'react';
import Footer from './components/Footer.js';
import Preguntas from './components/Preguntas.js';
import 'bootstrap/dist/css/bootstrap.min.css';



const Home = () => {
  return (
    <div>
        <Preguntas />
        <Footer />
    </div>
  );
};

export default Home;