// login.js
import React, { useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import showMessage from './showMessage';

const Login = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const credentials = await signInWithPopup(auth, provider);
      showMessage(`Welcome ${credentials.user.displayName}`);
    } catch (error) {
      showMessage(error.code, 'error');
    }
  };

  const handleLogout = async () => {
    await signOut(auth);
    showMessage('Goodbye');
  };

  return (
    <div className="login-container">
      {user ? (
        <div className="user-info">
          <img src={user.photoURL} alt="User" />
          <p>{user.displayName}</p>
          <button onClick={handleLogout} className="btn btn-primary">Logout</button>
        </div>
      ) : (
        <button onClick={handleGoogleLogin} className="btn btn-primary">Login with Google</button>
      )}
    </div>
  );
};

export default Login;
