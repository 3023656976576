import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import ReactPlayer from 'react-player';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Comentarios.css'; // Asegúrate de tener este archivo CSS para los estilos adicionales

const Comentarios = () => {
  const comentariosData = [
    {
      id: 1,
      videoUrl: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      nombre: 'Usuario 1',
      texto: '¡Gran producto! Realmente me encantó.',
      estrellas: 5,
    },
    {
      id: 2,
      videoUrl: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      nombre: 'Usuario 2',
      texto: 'Buen servicio al cliente. Recomiendo esta tienda.',
      estrellas: 4,
    },
    {
      id: 3,
      videoUrl: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      nombre: 'Usuario 3',
      texto: 'Me encanta este lugar. Siempre encuentro lo que necesito.',
      estrellas: 5,
    },
    {
      id: 4,
      videoUrl: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      nombre: 'Usuario 4',
      texto: 'Excelente calidad de productos. Muy satisfecho.',
      estrellas: 5,
    },
    {
      id: 5,
      videoUrl: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      nombre: 'Usuario 5',
      texto: 'Envío rápido y atención al cliente excepcional.',
      estrellas: 4,
    },
    {
      id: 6,
      videoUrl: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      nombre: 'Usuario 6',
      texto: '¡Nunca me decepciona! Siempre compro aquí.',
      estrellas: 5,
    },
    {
      id: 7,
      videoUrl: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      nombre: 'Usuario 7',
      texto: 'Buen precio y calidad. Definitivamente volveré a comprar.',
      estrellas: 4,
    },
    {
      id: 8,
      videoUrl: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      nombre: 'Usuario 8',
      texto: 'Mejor tienda en línea. Los productos son increíbles.',
      estrellas: 5,
    },
    {
      id: 9,
      videoUrl: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      nombre: 'Usuario 9',
      texto: 'Fácil proceso de compra y excelente servicio al cliente.',
      estrellas: 4,
    },
    {
      id: 10,
      videoUrl: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      nombre: 'Usuario 10',
      texto: 'Siempre encuentro lo que estoy buscando. ¡Altamente recomendado!',
      estrellas: 5,
    },
  ];

  const renderEstrellas = (cantidad) => {
    const estrellas = [];
    for (let i = 0; i < cantidad; i++) {
      estrellas.push(<span key={i} className="estrella">&#9733;</span>);
    }
    return estrellas;
  };

  return (
    <Container style={{ paddingTop: '50px', paddingBottom: '50px' }}>
      <Row>
        <Col>
          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            loop={true}
            autoplay={{ delay: 2000, disableOnInteraction: false }}
          >
            {comentariosData.map((comentario) => (
              <SwiperSlide key={comentario.id}>
                <Card className="comentario-card">
                  <ReactPlayer
                    url={comentario.videoUrl}
                    controls={false}
                    width="100%"
                    height="100%"
                  />
                  <Card.Body>
                    <Card.Title>{comentario.nombre}</Card.Title>
                    <Card.Text>{comentario.texto}</Card.Text>
                    <div className="estrellas">{renderEstrellas(comentario.estrellas)}</div>
     
                  </Card.Body>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>
      </Row>
    </Container>
  );
};

export default Comentarios;
