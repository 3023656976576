import React from 'react';
import Footer from './components/Footer.js';
import Carrito from './components/Carrito.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
  const handlePagar = (total) => {
    // Lógica para procesar el pago, por ejemplo, redirigir a una página de pago
    console.log('Total a pagar:', total);
    alert('Total a pagar: ' + total);
  };

  return (
    <div>
        <Carrito onPagar={handlePagar} />
        <Footer />
    </div>
  );
};

export default Home;
