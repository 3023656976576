// Unirte.js
import React from 'react';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { db, auth } from './login/firebase';
import showMessage from './login/showMessage';
import { collection, addDoc, serverTimestamp, doc, setDoc, getDoc } from 'firebase/firestore';

const Unirte = ({ item, onAnadirAlCarrito }) => {
    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        const user = auth.currentUser;
        try {
            const credentials = await signInWithPopup(auth, provider);
            showMessage("Welcome " + credentials.user.displayName);
            if (user) {
                // Obtener la referencia del carrito del usuario actual
                const carritoRef = doc(db, 'carritos', user.uid);

                // Agregar el item al subdocumento 'items' del carrito en Firestore
                const docRef = await addDoc(collection(carritoRef, 'items'), {
                    ...item,
                    timestamp: serverTimestamp(),
                });

                // Actualizar el contador en el carrito principal
                const carritoData = await getDoc(carritoRef);
                const itemCount = carritoData.data().itemCount || 0;
                await setDoc(carritoRef, { itemCount: itemCount + 1 }, { merge: true });

                onAnadirAlCarrito(docRef.id);
                alert(`Se añadió "${item.title}" al carrito`);
            } else {
                alert('Usuario no autenticado');
            }

    } catch (error) {
        console.error(error.code);
        // Handle different error codes here
    }
};

return (
    <button id="googleLogin" onClick={handleGoogleLogin} className="btn btn-primary">
        Comprar
    </button>
);
};

export default Unirte;
