import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, deleteDoc, setDoc, getDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from './login/firebase.js';

const Carrito = ({ onPagar }) => {
  const [carritoItems, setCarritoItems] = useState([]);

  useEffect(() => {
    const fetchCarritoItems = async () => {
      try {
        const currentUser = auth.currentUser;

        if (currentUser) {
          const carritoRef = doc(db, 'carritos', currentUser.uid);

          const unsubscribe = onSnapshot(collection(carritoRef, 'items'), (snapshot) => {
            const items = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, cantidad: 1 }));
            setCarritoItems(items);
          });

          return () => unsubscribe();
        }
      } catch (error) {
        console.error('Error al obtener los items del carrito:', error);
      }
    };

    fetchCarritoItems();
  }, [setCarritoItems]);

  const handleEliminarDelCarrito = async (itemId) => {
    try {
      const currentUser = auth.currentUser;

      if (currentUser) {
        const carritoRef = doc(db, 'carritos', currentUser.uid);

        await deleteDoc(doc(carritoRef, 'items', itemId));

        const carritoData = await getDoc(carritoRef);
        const itemCount = carritoData.data().itemCount || 0;
        await setDoc(carritoRef, { itemCount: itemCount - 1 }, { merge: true });
      }
    } catch (error) {
      console.error('Error al eliminar del carrito:', error);
    }
  };

  const handlePagar = async () => {
    try {
      const currentUser = auth.currentUser;
  
      if (currentUser) {
        const comprasEnProcesoRef = collection(db, 'comprasEnProceso');
        const carritoItemsCopy = [...carritoItems];
  
        // Crear una nueva compra en proceso para cada ítem del carrito
        for (const item of carritoItemsCopy) {
          if (item.imagenUrl) {
            await addDoc(comprasEnProcesoRef, {
              userId: currentUser.uid,
              descripcion: item.title,
              imagenUrl: item.imagenUrl,
              estado: 'enProceso',
              timestamp: serverTimestamp(),
            });
  
            // Eliminar el ítem del carrito
            await handleEliminarDelCarrito(item.id);
          } else {
            console.error('Error: La imagenUrl del ítem del carrito es undefined.');
          }
        }
  
        // Reiniciar el carrito u otras acciones según sea necesario
        onPagar();
      }
    } catch (error) {
      console.error('Error al realizar la compra:', error);
    }
  };
  const handleAgregarAlCarrito = (itemId) => {
    const updatedCarrito = carritoItems.map((item) =>
      item.id === itemId ? { ...item, cantidad: item.cantidad + 1 } : item
    );
    setCarritoItems(updatedCarrito);
  };

  const handleRestarDelCarrito = (itemId) => {
    const updatedCarrito = carritoItems.map((item) =>
      item.id === itemId && item.cantidad > 1 ? { ...item, cantidad: item.cantidad - 1 } : item
    );
    setCarritoItems(updatedCarrito);
  };

  return (
    <div>
      <h2>Carrito</h2>
      <ul>
        {carritoItems.map((item) => (
          <li key={item.id}>
            <strong>{item.title}</strong> - {item.description} - ${item.price} - Cantidad: {item.cantidad}
            <button onClick={() => handleRestarDelCarrito(item.id)}>Restar</button>
            <button onClick={() => handleAgregarAlCarrito(item.id)}>Agregar</button>
            <button onClick={() => handleEliminarDelCarrito(item.id)}>Eliminar</button>
          </li>
        ))}
      </ul>
      <button onClick={handlePagar}>Pagar</button>
    </div>
  );
};

export default Carrito;
