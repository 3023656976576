import React from 'react';
import { collection, addDoc, serverTimestamp, doc, setDoc, getDoc } from 'firebase/firestore';
import { db, auth } from './login/firebase.js';

const Anadir = ({ item, onAnadirAlCarrito }) => {
  const anadirAlCarrito = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        // Obtener la referencia del carrito del usuario actual
        const carritoRef = doc(db, 'carritos', user.uid);

        // Agregar el item al subdocumento 'items' del carrito en Firestore
        const docRef = await addDoc(collection(carritoRef, 'items'), {
          ...item,
          timestamp: serverTimestamp(),
        });

        // Actualizar el contador en el carrito principal
        const carritoData = await getDoc(carritoRef);
        const itemCount = carritoData.data().itemCount || 0;
        await setDoc(carritoRef, { itemCount: itemCount + 1 }, { merge: true });

        onAnadirAlCarrito(docRef.id); 
        alert(`Se añadió "${item.title}" al carrito`);
      } else {
        console.log('Usuario no autenticado');
      }
    } catch (error) {
      console.error('Error al añadir al carrito:', error);
    }
  };

  return (
    <button onClick={anadirAlCarrito} className="btn btn-success">
      Añadir
    </button>
  );
};

export default Anadir;
