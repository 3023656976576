import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Footer.css'; // Asegúrate de tener este archivo CSS para los estilos adicionales

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          {/* Sección de la imagen */}
          <Col md={3} className="footer-section">
            <img src="ruta_de_la_imagen" alt="Logo" />
          </Col>

          {/* Sección de redes sociales */}
          <Col md={3} className="footer-section">
            <h5>Redes Sociales</h5>
            {/* Agrega aquí tus íconos o enlaces a redes sociales */}
          </Col>

          {/* Sección de personas involucradas */}
          <Col md={3} className="footer-section">
            <h5>Personas Involucradas</h5>
            {/* Agrega aquí información sobre las personas involucradas */}
          </Col>

          {/* Sección de texto y título */}
          <Col md={3} className="footer-section">
            <h5>Título del Pie de Página</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonne merninisti licere mihi ista
              probare, quae sunt a te dicta? Refert tamen, quo modo.
            </p>
          </Col>
        </Row>
      </Container>

      {/* Sección de derechos de autor */}
      <div className="footer-bottom">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <p>&copy; 2023 Tu Empresa. Todos los derechos reservados.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
