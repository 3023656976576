import React, { useState, useEffect } from 'react';
import { FaHome, FaShoppingCart, FaQuestion, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Barra.css';
import Login from './login/login';
import Contador from './Contador';

const Barra = ({ user }) => {
  const [carritoCount, setCarritoCount] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    const actualizarContador = async () => {
      if (user) {
        try {
          const contador = await Contador(user.uid);
          console.log(contador);
          setCarritoCount(contador);
        } catch (error) {
          console.error('Error al obtener el contador:', error);
        }
      }
    };
    actualizarContador();
  }, [user]);


  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const checkAdminStatus = () => {
    setIsAdmin(user && user.isAdmin);
  };

  return (
    <div className="navbar">
      <div className="logo">Logo</div>
      <div className={`menu ${menuOpen ? 'open' : ''}`}>
        <button className="menu-btn" onClick={toggleMenu}>
          <FaBars />
        </button>
        <Link to="/" className="nav-btn">
          <FaHome /> Inicio
        </Link>
        <Link to="/carrito" className="nav-btn" >
          <FaShoppingCart /> Carrito ({carritoCount})
        </Link>
        <Link to="/preguntas" className="nav-btn">
          <FaQuestion /> Preguntas
        </Link>
        <Link to="/nosotros" className="nav-btn">
          <FaQuestion /> Nosotros
        </Link>
        {isAdmin && <Link to="/admin" className="nav-btn">Administrador</Link>}
        <Link to="/compras" className={`nav-btn ${user && user.hasPurchases ? '' : 'hidden'}`}>
          Compras
        </Link>
        <Login />
      </div>
    </div>
  );
};


export default Barra;
