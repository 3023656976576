import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './login/firebase.js';

const Contador = async (userId) => {
  try {
    const q = query(collection(db, 'carritos'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    let contador = 0;

    querySnapshot.forEach(() => {
      contador += 1;
    });

    return contador;
  } catch (error) {
    console.error('Error al obtener el contador del carrito:', error);
    return 0; // Devolver 0 en caso de error
  }
};

export default Contador;