import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Videos.css'; // Asegúrate de tener este archivo CSS para los estilos adicionales

const Videos = () => {
  return (
    <Container fluid>
      <Row>
        {/* Video en el lado izquierdo */}
        <Col md={6} className="video-section">
          {/* Puedes colocar aquí tu componente de video */}
          <div className="video-wrapper">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              title="Video"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
            ></iframe>
          </div>
        </Col>

        {/* Título y texto en el lado derecho */}
        <Col md={6} className="texto-section">
          <div className="contenido">
            <h2>Título del Contenido</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonne merninisti licere mihi ista
              probare, quae sunt a te dicta? Refert tamen, quo modo.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Videos;
