import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPlayer from 'react-player';
import Card from 'react-bootstrap/Card';

const PreguntasFrecuentes = () => {
    return (
        <Container style={{ marginTop: '100px', marginBottom: '50px' }}>
            <h2>Preguntas Frecuentes</h2>

            <Tab.Container id="preguntas-frecuentes-tabs" defaultActiveKey="pregunta1">
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link eventKey="pregunta1">Pregunta 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="pregunta2">Pregunta 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="pregunta3">Pregunta 3</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="pregunta4">Pregunta 4</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content>
                    {/* Pregunta 1 */}
                    <Tab.Pane eventKey="pregunta1">
                        <Row>
                            <Col md={6}>
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                    controls={true}
                                    width="100%"
                                />
                            </Col>
                            <Col md={6}>
                                <Card className="rounded-bottom mb-3">
                                    <div className="pregunta-content">
                                        <Card.Body>
                                            <Card.Title>¿Cómo funciona nuestro servicio?</Card.Title>
                                            <Card.Text>
                                                En este video, te explicamos cómo utilizar nuestro servicio y sacar el máximo provecho de todas las funcionalidades que ofrecemos.
                                            </Card.Text>
                                        </Card.Body>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Tab.Pane>

                    {/* Pregunta 2 */}
                    <Tab.Pane eventKey="pregunta2">
                        <Row>
                            <Col md={6}>
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                    controls={true}
                                    width="100%"
                                />
                            </Col>
                            <Col md={6}>
                                <Card className="rounded-bottom mb-3">
                                    <div className="pregunta-content">
                                        <Card.Body>
                                            <Card.Title>¿Cómo funciona nuestro servicio?</Card.Title>
                                            <Card.Text>
                                                En este video, te explicamos cómo utilizar nuestro servicio y sacar el máximo provecho de todas las funcionalidades que ofrecemos.
                                            </Card.Text>
                                        </Card.Body>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Tab.Pane>

                    {/* Pregunta 3 */}
                    <Tab.Pane eventKey="pregunta3">
                        <Row>
                            <Col md={6}>
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                    controls={true}
                                    width="100%"
                                />
                            </Col>
                            <Col md={6}>
                                <Card className="rounded-bottom mb-3">
                                    <div className="pregunta-content">
                                        <Card.Body>
                                            <Card.Title>¿Cómo funciona nuestro servicio?</Card.Title>
                                            <Card.Text>
                                                En este video, te explicamos cómo utilizar nuestro servicio y sacar el máximo provecho de todas las funcionalidades que ofrecemos.
                                            </Card.Text>
                                        </Card.Body>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        {/* Repite la estructura para la Pregunta 3 */}
                    </Tab.Pane>
                    
                    {/* Pregunta 4 */}
                    <Tab.Pane eventKey="pregunta4">
                    <Row>
                        <Col md={6}>
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                controls={true}
                                width="100%"
                            />
                        </Col>
                        <Col md={6}>
                            <Card className="rounded-bottom mb-3">
                                <div className="pregunta-content">
                                    <Card.Body>
                                        <Card.Title>¿Cómo funciona nuestro servicio?</Card.Title>
                                        <Card.Text>
                                            En este video, te explicamos cómo utilizar nuestro servicio y sacar el máximo provecho de todas las funcionalidades que ofrecemos.
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                        {/* Repite la estructura para la Pregunta 4 */}
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Container>
    );
};

export default PreguntasFrecuentes;
