import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Unirte from './unirte.js';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './login/firebase.js';
import Anadir from './Anadir'; // Importa el nuevo componente Añadir


const Tienda = () => {
  const [key, setKey] = useState('guias'); // Pestaña activa por defecto
  const [user, setUser] = useState(null);
  const [carrito, setCarrito] = useState([]);
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleAnadirAlCarrito = (item) => {
    setCarrito([...carrito, item]);
  };


  return (
    <Container style={{ marginTop: '50px' }}>
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="guias">Guias</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="examenes">Examenes</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="guias">
            <h2>Guias</h2>
            <Row>
              <Col md={6}>
                <h3>Preparatoria</h3>
                <Tab.Container id="preparatoria-tabs" defaultActiveKey="prepa-humanidades">
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="prepa-humanidades">Humanidades</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="prepa-ciencias">Ciencias</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="prepa-ingenierias">Ingenierias</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="prepa-medicina">Medicina</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content>
                        {/* Cards para cada sección de Preparatoria */}
                        <Tab.Pane eventKey="prepa-humanidades">
                          <Card className="rounded mb-3">
                            {/* Contenido de la Card de Humanidades */}
                            <Card.Img variant="top" src="ruta_de_la_imagen_humanidades" />
                            <Card.Body>
                              <Card.Title>Título Humanidades</Card.Title>
                              <Card.Text>Texto descriptivo sobre Humanidades.</Card.Text>
                              <Card.Text>$100</Card.Text>
                              {user ? (
                                <Anadir item={{ title: "Título Humanidades", description: "Texto descriptivo sobre Humanidades.", price: 100 }} onAnadirAlCarrito={handleAnadirAlCarrito} />
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="prepa-ciencias">
                          <Card className="rounded mb-3">
                            {/* Contenido de la Card de Humanidades */}
                            <Card.Img variant="top" src="ruta_de_la_imagen_humanidades" />
                            <Card.Body>
                              <Card.Title>Título Humanidades</Card.Title>
                              <Card.Text>Texto descriptivo sobre Humanidades.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="prepa-ingenierias">
                          <Card className="rounded mb-3">
                            {/* Contenido de la Card de Humanidades */}
                            <Card.Img variant="top" src="ruta_de_la_imagen_humanidades" />
                            <Card.Body>
                              <Card.Title>Título Humanidades</Card.Title>
                              <Card.Text>Texto descriptivo sobre Humanidades.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="prepa-medicina">
                          <Card className="rounded mb-3">
                            {/* Contenido de la Card de Humanidades */}
                            <Card.Img variant="top" src="ruta_de_la_imagen_humanidades" />
                            <Card.Body>
                              <Card.Title>Título Humanidades</Card.Title>
                              <Card.Text>Texto descriptivo sobre Humanidades.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
              <Col md={6}>
                <h3>Universidad</h3>
                <Tab.Container id="universidad-tabs" defaultActiveKey="humanidades">
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="humanidades">Humanidades</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="ciencias">Ciencias</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="ingenierias">Ingenierias</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="medicina">Medicina</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content>
                        {/* Cards para cada sección de universidad */}
                        <Tab.Pane eventKey="humanidades">
                          <Card className="rounded mb-3">
                            {/* Contenido de la Card de Humanidades */}
                            <Card.Img variant="top" src="ruta_de_la_imagen_humanidades" />
                            <Card.Body>
                              <Card.Title>Título Humanidades</Card.Title>
                              <Card.Text>Texto descriptivo sobre Humanidades.</Card.Text>
                              <Card.Text>$100</Card.Text>
                              {user ? (<Anadir item={{ title: "uni Humanidades", description: "uni sobre Humanidades.", price: 150 }} onAnadirAlCarrito={handleAnadirAlCarrito} />
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="ciencias">
                          <Card className="rounded mb-3">
                            {/* Contenido de la Card de Humanidades */}
                            <Card.Img variant="top" src="ruta_de_la_imagen_humanidades" />
                            <Card.Body>
                              <Card.Title>Título Humanidades</Card.Title>
                              <Card.Text>Texto descriptivo sobre Humanidades.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="ingenierias">
                          <Card className="rounded mb-3">
                            {/* Contenido de la Card de Humanidades */}
                            <Card.Img variant="top" src="ruta_de_la_imagen_humanidades" />
                            <Card.Body>
                              <Card.Title>Título Humanidades</Card.Title>
                              <Card.Text>Texto descriptivo sobre Humanidades.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="medicina">
                          <Card className="rounded mb-3">
                            {/* Contenido de la Card de Humanidades */}
                            <Card.Img variant="top" src="ruta_de_la_imagen_humanidades" />
                            <Card.Body>
                              <Card.Title>Título Humanidades</Card.Title>
                              <Card.Text>Texto descriptivo sobre Humanidades.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </Tab.Pane>



          <Tab.Pane eventKey="examenes">
            <h2>Examenes</h2>
            <Tab.Container id="examenes-tabs" defaultActiveKey="seccion1">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="seccion1">Sección 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="seccion2">Sección 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="seccion3">Sección 3</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="seccion4">Sección 4</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    {/* Cards para cada sección de examenes */}
                    <Tab.Pane eventKey="seccion1">
                      <Row>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        {/* Repite la estructura para las otras Cards de la sección 1 */}
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seccion2">
                      <Row>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        {/* Repite la estructura para las otras Cards de la sección 1 */}
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seccion3">
                      <Row>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        {/* Repite la estructura para las otras Cards de la sección 1 */}
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seccion4">
                      <Row>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="rounded mb-3">
                            <Card.Img variant="top" src="ruta_de_la_imagen" />
                            <Card.Body>
                              <Card.Title>Título 1</Card.Title>
                              <Card.Text>Texto descriptivo sobre la sección 1.</Card.Text>
                              {user ? (
                                <Button variant="success">Añadir</Button>
                              ) : (
                                <Unirte />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        {/* Repite la estructura para las otras Cards de la sección 1 */}
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Tab.Pane>


        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};

export default Tienda;
